import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {

  /*Recibimos mediante un Input el textto que queremeos mostrar en el header */
  @Input() titulo: string = '';

  constructor() { }

  ngOnInit() {}

}
